<script setup>
import { WdsIcon } from '@wds/components';

const { $tracking } = useNuxtApp();

const { isSmallScreen } = useMq();

const getImageUrl = computed(() => {
  return toValue(isSmallScreen)
    ? 'https://a.hwstatic.com/image/upload/f_auto,h_400,q_50/v1644922394/pwa/new/The_Spindrift_Hostel-img-sm.jpg'
    : 'https://a.hwstatic.com/image/upload/f_auto,w_504,h_568,q_50/v1644922394/pwa/new/The_Spindrift_Hostel-img.jpg';
});
function onBubbleButtonClick() {
  $tracking.onBubbleMessageClick({ parameter1: 'bubble message click' });
  const ref = document.getElementById('swis-section');
  ref.scrollIntoView();
}
</script>

<template>
  <section class="connect-with-travellers-section">
    <div
      id="cwt-section"
      class="cwt-section-content"
    >
      <img
        :src="getImageUrl"
        loading="lazy"
        alt="Connect with Travellers"
        height="568"
        width="504"
      />
      <div class="text-content">
        <p
          class="text-preheader"
          v-html="$t('t_CONNECTTITLE')"
        />
        <h2
          class="text-header"
          v-html="$t('t_CONNECTBODY')"
        />
        <button
          class="cta yellow"
          @click="onBubbleButtonClick"
        >
          {{ $t('t_YELLOWBOX') }}

          <WdsIcon
            asset="arrow-down"
            class="button-icon"
          />
        </button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.connect-with-travellers-section {
  position: relative;

  @include desktop {
    margin-left: auto;
    margin-right: auto;
  }
}

.cwt-section-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include tablet-large {
    margin-top: 0;
  }

  @include desktop {
    flex-direction: row;
  }

  & img {
    object-fit: contain;
    max-height: wds-rem(568px);
    display: block;
    margin: 0 auto;
    width: wds-rem(305px);

    @include tablet-large {
      width: wds-rem(375px);
    }

    @include desktop {
      order: 2;
    }

    @include desktop-large {
      width: wds-rem(568px);
      margin: unset;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include desktop {
      align-items: flex-start;
      padding-left: wds-rem(40px);
    }
  }

  .text-header,
  .text-preheader {
    & :deep(span) {
      color: $wds-color-pink;
    }
  }

  .text-preheader {
    @include title-5-bld;

    margin-top: calc(#{$wds-spacing-xxl} - #{$wds-spacing-s});
    margin-bottom: $wds-spacing-s;
    font-weight: 700;

    @include desktop {
      margin-top: $wds-spacing-xl;
    }

    @include desktop-large {
      @include title-3-bld;

      margin-top: unset;
      margin-bottom: $wds-spacing-m;
    }
  }

  .text-header {
    @include title-2-bld;

    text-align: center;
    hyphens: auto;

    @include desktop {
      @include title-1-bld;

      text-align: left;
      padding-right: $wds-spacing-xl;
    }

    @include desktop-large {
      @include lg-title-3-bld;
    }
  }
}

.button-icon {
  margin-left: $wds-spacing-s;

  &::before {
    height: $wds-spacing-m;
    width: $wds-spacing-m;
    font-size: $wds-spacing-m;
  }
}

.cta {
  width: fit-content;
  border: none;
  padding: $wds-spacing-s $wds-spacing-m;
  border-radius: $wds-border-radius-m;
  font-size: $wds-font-size-title-6;
  line-height: $wds-spacing-l;
  letter-spacing: $wds-letter-spacing-title-6;
  font-weight: 700;
  cursor: pointer;

  &.yellow {
    margin-top: calc(#{$wds-spacing-xxl} - #{$wds-spacing-s});
    margin-left: auto;
    margin-right: auto;
    background: linear-gradient(275.42deg, $wds-color-sunset-light 17%, #f8bf0a 79.31%);
    position: relative;

    @include desktop {
      margin-top: $wds-spacing-xxl;
      margin-left: unset;
      margin-right: unset;
    }

    @include desktop-large {
      margin-top: $wds-spacing-xxxl;
    }

    &::before {
      content: '';
      position: absolute;
      height: $wds-spacing-m;
      width: $wds-spacing-l;
      transform: skewY(-34deg) rotate(90deg);
      background: #f9bf0b;
      top: calc(100% - 13px);
      border-radius: wds-rem(3px);
      z-index: -1;
    }
  }
}

.cta-bubble-container {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  height: fit-content;

  @include tablet {
    top: wds-rem(-200px);
  }
}
</style>
